html,
body {
  padding: 0;
  margin: 0;
}

.portfolio {

  & .portfolio-section {
    padding: 16px 32px 32px 32px;


    & h4 {
      text-transform: uppercase;
    }

    & h6 {
      opacity: 0.5;
      text-transform: uppercase;
      margin-top: -22px;
    }

    &.dark {
      background-color: black;

      & h4,h6 {
        color: white;
      }

    }

    &.lightgreen {
      background-color: #a3e2af;
    }

    &.pink {
      background-color: #ffd6d6;
    }

    &.yellow {
      background-color: #fae95b;
    }

    &.lime {
      background-color: #ddff97;
    }

    &.orange {
      background-color: #ffa47f;
    }

    &.elementum {
      background-color: #bce2ff;
    }

    &.ukrtelecom {
      background-color: #f9ffb4;
    }

    &.amcu {
      background-color: #efd6c3;
    }

    &.studio {
      background-color: #e4e2e2;
    }

    &.openaccess {
      background-color: orange;
    }

    &.grace {
      background-color: #cbe6d3;
    }

    &.ponomarev {
      background-color: #bcbcbc;
    }

    &.renders {
      background-color: #f9f9f9;
    }

    &.salsa {
      background-color: #f8ffc3;
    }

    &.avanti {
      background-color: #ffe3a0;
    }

    &.avia {
      background-color: #c0e5f3;
    }

    &.jumble {
      background-color: #d276a8;
    }

    &.visit {
      background-color: #f3eeeb;
    }

    &.intranet {
      background-color: #f2f2f2;
    }

    &.clear {
      background-color: #ccdbd2;
    }

    &.med {
      background-color: #dce8de;
    }

    & .pictures-container {
      --columns: 3;
      --gap: 32px;

      @media (max-width: 768px) {
        --columns: 2;
      }

      @media (max-width: 320px) {
        --columns: 1;
      }
      
      -webkit-column-count: var(--columns);
      -moz-column-count: var(--columns);
      column-count: var(--columns);
      

      -webkit-column-gap: var(--gap);
      -moz-column-gap: var(--gap);
      column-gap: var(--gap);



      & .picture {
        display: inline-block;
        width: 100%;
        margin-bottom: var(--gap);

        & img {
          display: block;
          width: 100%;
        }

        & span {
          font-family: sans-serif;
          display: block;
          padding: 10px;
        }
      }
    }
  }
}